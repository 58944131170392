import React from 'react';
import styles from './ActiveArea.module.css';

const InputItem = ({ icon, text, onClick, isActive }) => (
  <div
    className={`${styles.inputItem} ${isActive ? styles.active : ''}`}
    tabIndex="0"
    role="button"
    onClick={onClick}
  >
    <img loading="lazy" src={icon} alt="" className={styles.inputIcon} />
    <div className={styles.inputText}>{text}</div>
  </div>
);

export default InputItem;
