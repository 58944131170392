import React, { useState } from 'react'; 
import styles from './ActiveArea.module.css';
import InputItem from './InputItem';

const inputItems = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8e728631b70606e555a26b5a2b5b1cde32b61305fbc1f689da29049bf25af780?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7", text: "Add Phone Number" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4735d46f7c0736c3633f483d24809ff8056354f6252ecf45a4239c1ac8a06913?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7", text: "Add Text to Read" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b3fa76cd6f675d3c6a4e9d4f911e95a223f48dc09c4dacb8030fa54d3c20fa8c?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7", text: "Add Instagram ID" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ab269096e248bd0906598fb4b60255bfc58faf64586ae6297b07a52e8afd9e2c?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7", text: "Add Link" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9f930ab72882b7ad36a4fda484ac23199689b413601233bb1574e1716fc7680?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7", text: "Add Location" }
];

function ActiveArea() {
  const [activeInput, setActiveInput] = useState(null);
  const [linkId, setLinkId] = useState('3o1Ity1QS'); // Default linkId
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleClick = (index) => {
    setActiveInput(index);
    // Logic to show appropriate keyboard or type indicator
  };

  const handleActivate = async () => {
    if (activeInput === null) {
      alert("Please select an action first.");
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/activateLink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linkId,
          action: inputItems[activeInput].text,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setLinkId(result.linkId); // Adjust based on your backend response
      } else {
        console.error('Failed to activate:', response.statusText);
      }
    } catch (error) {
      console.error('Error during activation:', error);
    }
  };

  return (
    <section className={styles.activeArea}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbd5c322d1ef3981d33af5679d10e92cda3e18abe9a3cc18eea5b0cab3ad1a79?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7" alt="Logo" className={styles.logoImage} />
        </div>
        <h2 className={styles.note}>Choose One Action</h2>
        <div className={styles.inputContainer}>
          {inputItems.map((item, index) => (
            <InputItem
              key={index}
              icon={item.icon}
              text={item.text}
              onClick={() => handleClick(index)}
              isActive={activeInput === index}
            />
          ))}
        </div>
        <div className={styles.linkId}>{linkId}</div>
        <div className={styles.buttonContainer}>
          <button className={styles.buttonText} onClick={handleActivate}>
            Activate
          </button>
          <p className={styles.buttonNote}>
            کیوآرمی برای همیشه روی این قابلیت تنظیم میشود!{" "}
            <strong>این عملیات قابل برگشت نیست.</strong>
          </p>
        </div>
        <div className={styles.credits}>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d51abfb8101bb6dd3d76b623461f6f86a20d2f6653bb532f1a957ebb52d05bc5?placeholderIfAbsent=true&apiKey=f72cf25e496e4c7a9835414d841b3fe7" alt="Credits" className={styles.creditsImage} />
        </div>
      </div>
    </section>
  );
}

export default ActiveArea;
